import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import { number, object } from "prop-types"
import React from "react"

const useStyles = makeStyles(theme => ({
  textInput: {
    margin: theme.spacing(1, 0),
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.common.white,
      borderRadius: "5px 25px 5px 5px",
      border: `${theme.palette.common.black} 2px solid`,
      outline: 0,
      fontFamily: "Exo, Ariel, Helvetica",
    },
    "& div.Mui-focused": {
      backgroundColor: theme.palette.common.white,
    },
    "& label.MuiFormLabel-root": {
      fontFamily: "Exo, Ariel, Helvetica",
    },
    "& label.Mui-focused, & label.MuiFormLabel-filled": {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      borderRadius: "4px 12px 0 0",
      padding: theme.spacing(1, 1.75),
      border: `${theme.palette.common.black} 2px solid`,
      clipPath: `polygon(0% 0%, 0% 30%, 2.5% 30%, 2.5% 90%, 97.5% 90%, 97.5% 30%, 100% 30%, 100% 0%)`,
    },
    "& fieldset": {
      borderColor: "transparent !important",
      borderWidth: "0 !important",
    },
  },
}))

const ContactForm = ({ maxWidth, button }) => {
  const formStyling = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: button.font.fontFamily,
    color: button.text,
  }

  const buttonStyling = {
    marginTop: 15,
    color: button.text,
    backgroundColor: button.background,
    border: "none",
    fontFamily: button.font.fontFamily,
    fontWeight: "bold",
    padding: "15px 35px",
    borderRadius: "5px 20px 5px 5px",
    cursor: "pointer",
  }

  return (
    <form
      action="/bedankt"
      method="POST"
      style={{
        margin: "50px auto",
        maxWidth: maxWidth,
        ...formStyling,
      }}
      className="resp-form"
      data-netlify="true"
      name="Contact Form"
    >
      <input type="hidden" name="form-name" value="Contact Form" />
      <h2>contact</h2>

      <p style={{ marginBottom: 50 }} className="resp-hide">
        Vul het contact formulier hieronder in en ik neem zo spoedig mogelijk
        contact met u op.
      </p>

      <section
        style={{ width: "100%", maxWidth: maxWidth * 0.5, ...formStyling }}
      >
        <TextInput name="bedrijfsnaam" />
        <TextInput name="naam" />
        <TextInput name="telefoonnummer" />
        <TextInput name="email" />

        <TextInput name="bericht" multiline rows={6} />

        <button type="submit" style={buttonStyling} className="scale-link">
          verzenden
        </button>
      </section>
    </form>
  )
}

const TextInput = props => {
  const { name } = props
  const classes = useStyles()

  return (
    <TextField
      name={name}
      id={name}
      label={`Uw ${name}`}
      fullWidth
      variant="outlined"
      className={classes.textInput}
      {...props}
    />
  )
}

ContactForm.propTypes = {
  button: object.isRequired,
  maxWidth: number,
}

ContactForm.defaultProps = {
  maxWidth: 1920,
}

export default ContactForm
